import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import { RiExternalLinkLine } from 'react-icons/ri';

import DateTime, { parse } from 'src/components/DateTime';
import ExternalLink from 'src/components/ExternalLink';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import URLs from 'src/utils/urls';
import * as styles from './BlogArchivePage.module.sass';


const renderYearSection = (year, posts) => {
  return (
    <section key={year} className={styles.section}>
      <h2 className={styles.year}>{year}</h2>

      <ul>
        {posts.map(({ node }) => {
          const slug = node.fields.slug;
          const { title, date, external } = node.frontmatter;

          return (
            <li key={slug} className={styles.post}>
              <DateTime date={date.published} format="shortest" />

              <span>
                <Link to={URLs.postPath(slug)}>
                  {title}
                </Link>

                {external && external.url && (
                  <ExternalLink to={`${URLs.postPath(slug)}go`} label="Open External Article">
                    <RiExternalLinkLine className={styles.iconExternal} />
                  </ExternalLink>
                )}
              </span>
            </li>
          );
        })}
      </ul>
    </section>
  );
};


const AllBlogPosts = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges;

  const grouped = _.groupBy(posts, (p) => parse(p.node.frontmatter.date.published).year());
  const years = _.sortBy(Object.keys(grouped)).reverse();

  return (
    <Layout location={location}>
      <SEO
        title="All Articles"
        location={location}
      />

      <h1 className={styles.title}>
        Articles
      </h1>

      <main className={styles.container}>
        {years.map(year => renderYearSection(year, grouped[year]))}
      </main>
    </Layout>
  );
};


export default AllBlogPosts;
