import { graphql } from 'gatsby';
import Component from 'src/templates/BlogArchivePage';
export default Component;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort:   { fields: [frontmatter___date___published], order: DESC }
      filter: { fields: { type: { eq: "blog" } } }
    ) { ...PostFragment }
  }

  fragment PostFragment on MarkdownRemarkConnection {
    edges {
      node {
        excerpt
        frontmatter {
          date { published }
          title
          description
          external { url }
        }
        fields { slug }
      }
    }
  }
`;
